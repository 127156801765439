<template>
  <div>
    <v-snackbar v-model="kayitYapildi" color="success" top>
      <span v-if="type == 'add'">Kayıt Başarılı</span>
      <span v-else>Kayıt başarı ile güncellendi. Yönlediriliyor..</span>
    </v-snackbar>

    <v-dialog v-model="bankaKoduDialog" eager max-width="940" persistent>
      <v-card>
        <v-card-title>
          Banka Kodu
          <v-spacer />
          <v-btn icon color="rgb(73, 73, 73)" @click="bankaKoduDialog = false">
            <v-icon>
              {{ icons.mdiWindowClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="bankHeaders"
            :items="bankItems"
            :items-per-page="500"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="bankaKoduDialog = false"> KAPAT </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="entKoduDialog" eager max-width="940" persistent>
      <v-card>
        <v-card-title>
          Banka Entegrasyon Kodları
          <v-spacer />
          <v-btn icon color="rgb(73, 73, 73)" @click="entKoduDialog = false">
            <v-icon>
              {{ icons.mdiWindowClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="entHeaders"
            :items="entItems"
            :items-per-page="500"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="entKoduDialog = false"> KAPAT </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
      <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
        <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
          <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
            {{ icons.mdiPointOfSale }}
          </v-icon>
          Pos Tanımları
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-alert v-if="type == 'edit'" color="tertiary">
        <strong class="white--text">{{ id1 }}</strong>
        <span class="white--text"> numaralı kayıt üzerinde işlem yapıyorsunuz!</span>
      </v-alert>
      <v-alert v-else-if="type == 'add' && id1 !== undefined" color="tertiary">
        <strong class="white--text">{{ id1 }}</strong>
        <span class="white--text">
          numaralı kaydı referans alarak <b><u>YENİ KAYIT</u></b> oluşturuyorsunuz!</span
        >
      </v-alert>

      <!-- form -->

      <v-form ref="form" style="padding: 20px">
        <v-row>
          <v-col cols="12" md="3">
            <label for="firstname">Banka Hesap Adı *</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-text-field
              v-model="banka_adi"
              label="Banka Hesap Adı"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="email">Kod *</label>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="kod"
              label="Kod"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            ></v-text-field>
            <div class="mt-1 text-caption">
              Banka kodları için
              <span style="color: rgb(43, 43, 242); cursor: pointer" @click="bankaKoduDialog = true"> tıklayınız</span>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <label for="email">ENT. Kodu *</label>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="ent_kodu"
              label="Ent Kodu"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            ></v-text-field>
            <div class="mt-1 text-caption">
              Entegrasyon kodları için
              <span style="color: rgb(43, 43, 242); cursor: pointer" @click="entKoduDialog = true"> tıklayınız</span>
            </div>
          </v-col>

          <v-col cols="12" md="3">
            <label for="email">ERP Kodu</label>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="ozel_kod" outlined dense hide-details></v-text-field>
            <div class="mt-1 text-caption">ERP tarafındaki özel banka kodu</div>
          </v-col>
          <v-col cols="12" md="2">
            <label for="email">Kayıt Anahtarı</label>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="banka_postkey" outlined dense hide-details></v-text-field>
            <div class="mt-1 text-caption">Postkey</div>
          </v-col>
          <v-col cols="12" md="3">
            <label for="mobile">Kart Tipi</label>
          </v-col>

          <v-col cols="12" md="3">
            <span
              class="text-caption text-right"
              style="color: rgb(43, 43, 242); cursor: pointer"
              @click="tumKartTipleri()"
            >
              tümünü seç</span
            >
            <v-select
              v-model="card_brand"
              multiple
              dense
              :items="card_brand_items"
              label="Kart Tipi"
              outlined
            ></v-select>
          </v-col>
          <!-- x {{ card_brand }}x -->
          <v-col cols="12" md="5"> </v-col>

          <v-col cols="12" md="3">
            <label for="mobile">Sıra *</label>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="sira"
              label="Sıra"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6"> </v-col>

          <v-col cols="12" md="3">
            <label for="mobile">Renk Seçeneği</label>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="renk" outlined dense hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="6"> </v-col>

          <v-col offset-md="3" cols="12">
            <v-checkbox v-model="aktif" color="secondary" label="Aktif" class="mt-0" hide-details></v-checkbox>
          </v-col>
          <v-col offset-md="3" cols="12">
            <v-checkbox
              v-model="pos_test_mode"
              color="secondary"
              label="Test Modu Açık?"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col offset-md="3" cols="12">
            <v-checkbox
              v-model="odeme_alt_yapi_firmasi"
              color="secondary"
              label="Ödeme Altyapı Firması"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="3">
            <label>Notlar</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-textarea v-model="notlar" label="Notlar" rows="2" outlined></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label>Pos Türü</label>
          </v-col>

          <v-col cols="12" md="9">
            <v-select v-model="pos_turu" multiple dense :items="pos_turu_items" label="Pos Türü" outlined></v-select>
          </v-col>
        </v-row>

        <v-col class="mt-14">
          <h2 class="ml-3">Sanal Pos Ayarları</h2>
          <h6 class="ml-3 mt-1">Banka sanal pos entegrasyonu için gerekli bilgiler</h6>
        </v-col>

        <v-tabs v-model="currentTab" color="secondary" style="padding: 20px">
          <v-tab>Prod</v-tab>
          <v-tab>Test</v-tab>

          <v-tab-item>
            <v-divider class="mt-1"></v-divider>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Client ID (MID)</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_client_id" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Terminal No (TID)</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_terminal_no" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Kullanıcı Adı</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_name" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Şifre</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_pass" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Store Key/Merchant Key </label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_store_key" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>API URL</label>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="prod_api_url" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>UCD URL (@URL/3d)</label>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="prod_ucd_url" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>MPI URL</label>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="prod_mpi_url" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>POSNET ID</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_posnetid" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Anahtar XCIP (ENCKEY/Storekey/App Key)</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="prod_keyxcip" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label for="mobile">3D Tipi</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select v-model="prod__3d_type" dense :items="three_d_items" label="3D Tipi" outlined></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-divider class="mt-1"></v-divider>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Client ID (MID)</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_client_id" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Terminal No (TID)</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_terminal_no" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Kullanıcı Adı</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_name" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Şifre/App Secret</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_pass" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Store Key</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_store_key" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>API URL</label>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="test_api_url" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>UCD URL (@URL/3d)</label>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="test_ucd_url" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>MPI URL</label>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field v-model="test_mpi_url" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>POSNET ID</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_posnetid" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label>Anahtar XCIP (ENCKEY/Storekey)</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field v-model="test_keyxcip" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label for="mobile">3D Tipi</label>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select v-model="test__3d_type" dense :items="three_d_items" label="3D Tipi" outlined></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>

        <v-col offset-md="5" cols="12">
          <v-btn :loading="loading" color="tertiary" class="white--text" @click="validate">
            <span v-if="type == 'add'"> Kaydet ve Yeni Oluştur</span>
            <span v-else>Güncelle</span>
          </v-btn>
        </v-col>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiPointOfSale, mdiWindowClose } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const { router } = useRouter()
    const gelenBank = ref([])
    const loading = ref(false)
    const type = ref('add')
    const kayitYapildi = ref(false)

    const banka_adi = ref('')
    const kod = ref('')
    const ent_kodu = ref('')
    const ozel_kod = ref('')
    const banka_postkey = ref('')
    const card_brand = ref([])
    const sira = ref('')
    const renk = ref('')
    const aktif = ref(0)
    const pos_test_mode = ref(0)
    const notlar = ref('')
    const pos_turu = ref([])
    const prod_client_id = ref('')
    const prod_terminal_no = ref('')
    const prod_name = ref('')
    const prod_pass = ref('')
    const prod_store_key = ref('')
    const prod_api_url = ref('')
    const prod_ucd_url = ref('')
    const prod_mpi_url = ref('')
    const prod_posnetid = ref('')
    const prod_keyxcip = ref('')
    const prod__3d_type = ref([])
    const test_client_id = ref('')
    const test_terminal_no = ref('')
    const test_name = ref('')
    const test_pass = ref('')
    const test_store_key = ref('')
    const test_api_url = ref('')
    const test_ucd_url = ref('')
    const test_mpi_url = ref('')
    const test_posnetid = ref('')
    const test_keyxcip = ref('')
    const test__3d_type = ref([])
    const odeme_alt_yapi_firmasi = ref(false)
    const id1 = ref('')

    const firstname = ref('')
    const email = ref('')
    const mobile = ref()
    const password = ref()
    const checkbox = ref(false)
    const selectedOptions = ref([])
    const states = ['Normal', '3D']
    const currentTab = ref(0)
    const bankaKoduDialog = ref(false)
    const entKoduDialog = ref(false)
    const card_brand_items = [
      'Bonus',
      'World',
      'Advantage',
      'Cardfinans',
      'Axess',
      'Maximum',
      'Paraf',
      'BankkartCombo',
      'Combo',
      'Miles&Smiles',
      'Wings',
      'Sağlam Kart',
      'Happy',
    ]
    const pos_turu_items = [
      { text: 'Normal', value: 1 },
      { text: '3D', value: 2 },
    ]
    const three_d_items = ['Varsayılan', '3D', '3D_PAY']

    const nameRules = [v => !!v || 'Name is required']

    const valid = ref(true)

    onMounted(() => {
      // console.log(router.currentRoute.params.id)
      // console.log(store.state.gidenBank)
      // if (!router.currentRoute.params.id) {
      //   router.push({ name: 'pos-tanimlari' })
      // }
      debugger
      if (router.currentRoute.params.copy === 'false') {
        router.push({ name: 'pos-tanimlari' })
      }
      if (router.currentRoute.params.id && router.currentRoute.params.copy === 'true') {
        console.log('add copy')

        id1.value = router.currentRoute.params.id
        type.value = 'add'
        gelenBank.value = store.state.gidenBank
        banka_adi.value = gelenBank.value.banka_adi
        aktif.value = gelenBank.value.aktif
        banka_postkey.value = gelenBank.value.banka_postkey
        kod.value = gelenBank.value.kod
        ozel_kod.value = gelenBank.value.ozel_kod
        pos_test_mode.value = gelenBank.value.pos_test_mode
        pos_turu.value = gelenBank.value.pos_turu.split(',').map(value => parseInt(value.trim()))
        renk.value = gelenBank.value.renk
        sira.value = gelenBank.value.sira
        card_brand.value = gelenBank.value.card_brand.split(',').map(item => item.trim())
        ent_kodu.value = gelenBank.value.ent_kodu
        notlar.value = gelenBank.value.notlar
        odeme_alt_yapi_firmasi.value = gelenBank.value.odeme_alt_yapi_firmasi

        // sanal pos

        // prod

        prod_api_url.value = gelenBank.value.pos_settings.prod.api_url
        prod_client_id.value = gelenBank.value.pos_settings.prod.client_id
        prod_keyxcip.value = gelenBank.value.pos_settings.prod.keyxcip
        prod_mpi_url.value = gelenBank.value.pos_settings.prod.mpi_url
        prod_name.value = gelenBank.value.pos_settings.prod.name
        prod_pass.value = gelenBank.value.pos_settings.prod.pass
        prod_posnetid.value = gelenBank.value.pos_settings.prod.posnetid
        prod_store_key.value = gelenBank.value.pos_settings.prod.store_key
        prod_terminal_no.value = gelenBank.value.pos_settings.prod.terminal_no
        prod_ucd_url.value = gelenBank.value.pos_settings.prod.ucd_url
        prod__3d_type.value = gelenBank.value.pos_settings.prod._3d_type

        // test

        test_api_url.value = gelenBank.value.pos_settings.test.api_url
        test_client_id.value = gelenBank.value.pos_settings.test.client_id
        test_keyxcip.value = gelenBank.value.pos_settings.test.keyxcip
        test_mpi_url.value = gelenBank.value.pos_settings.test.mpi_url
        test_name.value = gelenBank.value.pos_settings.test.name
        test_pass.value = gelenBank.value.pos_settings.test.pass
        test_posnetid.value = gelenBank.value.pos_settings.test.posnetid
        test_store_key.value = gelenBank.value.pos_settings.test.store_key
        test_terminal_no.value = gelenBank.value.pos_settings.test.terminal_no
        test_ucd_url.value = gelenBank.value.pos_settings.test.ucd_url
        test__3d_type.value = gelenBank.value.pos_settings.test._3d_type

        // varsayilan_tek_cekim.value = gelenBank.value.varsayilan_tek_cekim
        // const method = ''
        // store
        //   .dispatch('postMethod', {
        //     method: 'getPosDefinations',
        //     id: id,
        //   })
        //   .then(response => {
        //     console.log(response)
        //   })
      } else if (router.currentRoute.params.id) {
        console.log('edit')

        id1.value = router.currentRoute.params.id
        type.value = 'edit'
        gelenBank.value = store.state.gidenBank
        banka_adi.value = gelenBank.value.banka_adi
        aktif.value = gelenBank.value.aktif
        banka_postkey.value = gelenBank.value.banka_postkey
        kod.value = gelenBank.value.kod
        ozel_kod.value = gelenBank.value.ozel_kod
        pos_test_mode.value = gelenBank.value.pos_test_mode
        pos_turu.value = gelenBank.value.pos_turu.split(',').map(value => parseInt(value.trim()))
        renk.value = gelenBank.value.renk
        sira.value = gelenBank.value.sira
        card_brand.value = gelenBank.value.card_brand.split(',').map(item => item.trim())
        ent_kodu.value = gelenBank.value.ent_kodu
        notlar.value = gelenBank.value.notlar
        odeme_alt_yapi_firmasi.value = gelenBank.value.odeme_alt_yapi_firmasi

        // sanal pos

        // prod

        prod_api_url.value = gelenBank.value.pos_settings.prod.api_url
        prod_client_id.value = gelenBank.value.pos_settings.prod.client_id
        prod_keyxcip.value = gelenBank.value.pos_settings.prod.keyxcip
        prod_mpi_url.value = gelenBank.value.pos_settings.prod.mpi_url
        prod_name.value = gelenBank.value.pos_settings.prod.name
        prod_pass.value = gelenBank.value.pos_settings.prod.pass
        prod_posnetid.value = gelenBank.value.pos_settings.prod.posnetid
        prod_store_key.value = gelenBank.value.pos_settings.prod.store_key
        prod_terminal_no.value = gelenBank.value.pos_settings.prod.terminal_no
        prod_ucd_url.value = gelenBank.value.pos_settings.prod.ucd_url
        prod__3d_type.value = gelenBank.value.pos_settings.prod._3d_type

        // test

        test_api_url.value = gelenBank.value.pos_settings.test.api_url
        test_client_id.value = gelenBank.value.pos_settings.test.client_id
        test_keyxcip.value = gelenBank.value.pos_settings.test.keyxcip
        test_mpi_url.value = gelenBank.value.pos_settings.test.mpi_url
        test_name.value = gelenBank.value.pos_settings.test.name
        test_pass.value = gelenBank.value.pos_settings.test.pass
        test_posnetid.value = gelenBank.value.pos_settings.test.posnetid
        test_store_key.value = gelenBank.value.pos_settings.test.store_key
        test_terminal_no.value = gelenBank.value.pos_settings.test.terminal_no
        test_ucd_url.value = gelenBank.value.pos_settings.test.ucd_url
        test__3d_type.value = gelenBank.value.pos_settings.test._3d_type

        // varsayilan_tek_cekim.value = gelenBank.value.varsayilan_tek_cekim
        // const method = ''
        // store
        //   .dispatch('postMethod', {
        //     method: 'getPosDefinations',
        //     id: id,
        //   })
        //   .then(response => {
        //     console.log(response)
        //   })
      } else {
        console.log('add')
        type.value = 'add'
      }

      // const id = 11096
    })
    const tumKartTipleri = () => {
      if (card_brand.value != card_brand_items) {
        card_brand.value = card_brand_items
      } else {
        card_brand.value = []
      }
    }

    const sendFields = () => {
      aktif.value == true ? (aktif.value = '1') : (aktif.value = '0')
      pos_test_mode.value == true ? (pos_test_mode.value = '1') : (pos_test_mode.value = '0')

      store
        .dispatch('postMethod', {
          method: 'savePosDefination',
          id: type.value === 'edit' ? router.currentRoute.params.id : '',
          'field[banka_adi]': banka_adi.value,
          'field[kod]': kod.value,
          'field[ent_kodu]': ent_kodu.value,
          'field[ozel_kod]': ozel_kod.value,
          'field[banka_postkey]': banka_postkey.value,
          'field[card_brand]': card_brand.value,
          'field[sira]': sira.value,
          'field[renk]': renk.value,
          'field[aktif]': aktif.value,
          'field[pos_test_mode]': pos_test_mode.value,
          'field[notlar]': notlar.value,
          'field[pos_turu]': pos_turu.value,
          'pos[prod][client_id]': prod_client_id.value,
          'pos[prod][terminal_no]': prod_terminal_no.value,
          'pos[prod][name]': prod_name.value,
          'pos[prod][pass]': prod_pass.value,
          'pos[prod][store_key]': prod_store_key.value,
          'pos[prod][api_url]': prod_api_url.value,
          'pos[prod][ucd_url]': prod_ucd_url.value,
          'pos[prod][mpi_url]': prod_mpi_url.value,
          'pos[prod][posnetid]': prod_posnetid.value,
          'pos[prod][keyxcip]': prod_keyxcip.value,
          'pos[prod][_3d_type]': prod__3d_type.value,
          'pos[test][client_id]': test_client_id.value,
          'pos[test][terminal_no]': test_terminal_no.value,
          'pos[test][name]': test_name.value,
          'pos[test][pass]': test_pass.value,
          'pos[test][store_key]': test_store_key.value,
          'pos[test][api_url]': test_api_url.value,
          'pos[test][ucd_url]': test_ucd_url.value,
          'pos[test][mpi_url]': test_mpi_url.value,
          'pos[test][posnetid]': test_posnetid.value,
          'pos[test][keyxcip]': test_keyxcip.value,
          'pos[test][_3d_type]': test__3d_type.value,
        })

        .then(response => {
          console.log(response)
          if (response.error == 0) {
            kayitYapildi.value = true
            setTimeout(() => {
              router.push({ name: 'pos-tanimlari' })
            }, 1500)
          } else {
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
            loading.value = false
          }

          // if (response.error == 0) {
          //
          // } else {

          // }
        })
    }

    const validate = () => {
      loading.value = true
      form.value.validate()
      console.log(form.value.validate())
      if (form.value.validate() == false) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        loading.value = false
      } else {
        console.log('devam')
        sendFields()
      }
    }

    const form = ref(null)

    const bankHeaders = [
      {
        text: 'Banka',
        value: 'banka',
        width: '400px',
        sortable: false,
      },
      {
        text: 'Kod',
        value: 'kod',
        width: '100px',
        sortable: false,
      },
    ]

    const entHeaders = [
      {
        text: 'Banka',
        value: 'banka',
        width: '400px',
        sortable: false,
      },
      {
        text: 'Kod',
        value: 'kod',
        width: '100px',
        sortable: false,
      },
    ]

    const bankItems = [
      { banka: 'T.C. Ziraat Bankası A.Ş.', kod: 10 },
      { banka: 'T. Halk Bankası A.Ş.', kod: 12 },
      { banka: 'T. Vakıflar Bankası T.A.O.', kod: 15 },
      { banka: 'T. Ekonomi Bankası A.Ş.', kod: 32 },
      { banka: 'Akbank T.A.Ş.', kod: 46 },
      { banka: 'Şekerbank T.A.Ş.', kod: 59 },
      { banka: 'T. Garanti Bankası A.Ş.', kod: 62 },
      { banka: 'T. İş Bankası A.Ş.', kod: 64 },
      { banka: 'Yapı Kredi Bankası A.Ş', kod: 67 },
      { banka: 'Citibank A.Ş.', kod: 92 },
      { banka: 'TurkishBank A.Ş', kod: 96 },
      { banka: 'ING Bank A.Ş.', kod: 99 },
      { banka: 'Fibabanka A.Ş.', kod: 103 },
      { banka: 'Turkland Bank A.Ş.', kod: 108 },
      { banka: 'Tekstil Bankası A.Ş.', kod: 109 },
      { banka: 'Finansbank A.Ş.', kod: 111 },
      { banka: 'HSBC Bank A.Ş.', kod: 123 },
      { banka: 'Alternatifbank A.Ş.', kod: 124 },
      { banka: 'Burgan Bank A.Ş.', kod: 125 },
      { banka: 'DenizBank A.Ş.', kod: 134 },
      { banka: 'Anadolubank A.Ş.', kod: 135 },
      { banka: 'Aktif Yatırım Bankası A.Ş.', kod: 143 },
      { banka: 'Odea Bank A.Ş.', kod: 146 },
      { banka: 'Albaraka Türk Katılım Bankası A.Ş.', kod: 203 },
      { banka: 'Kuveyt Türk Katılım Bankası A.Ş.', kod: 205 },
      { banka: 'Türkiye Finans Katılım Bankası A.Ş.', kod: 206 },
      { banka: 'Asya Katılım Bankası A.Ş.', kod: 208 },
    ]

    const entItems = [
      { banka: 'Akbank', kod: 'AKB' },
      { banka: 'Albaraka Türk', kod: 'ALB' },
      { banka: 'Alternatif Bank', kod: 'ALT' },
      { banka: 'AnadoluBank', kod: 'AND' },
      { banka: 'Bank Asya', kod: 'BAS' },
      { banka: 'BankPozitif', kod: 'BPO' },
      { banka: 'CitiBank', kod: 'CTI' },
      { banka: 'DenizBank', kod: 'DNZ' },
      { banka: 'EuroBank Tekfon', kod: 'EUR' },
      { banka: 'Finansbank', kod: 'FIN' },
      { banka: 'Fibabank', kod: 'FIB' },
      { banka: 'ING Bank', kod: 'ING' },
      { banka: 'Garanti Bank', kod: 'GRT' },
      { banka: 'Halkbank', kod: 'HLK' },
      { banka: 'Kuveyt Türk', kod: 'KVY' },
      { banka: 'Millenium Bank', kod: 'MIL' },
      { banka: 'Şeker Bank', kod: 'SKR' },
      { banka: 'Turkland Bank', kod: 'TLB' },
      { banka: 'TEB', kod: 'TEB' },
      { banka: 'Tekstil Bank', kod: 'TKS' },
      { banka: 'Turkish Bank', kod: 'TKS' },
      { banka: 'Türkiye Finans', kod: 'TRF' },
      { banka: 'Türkiye İş Bankası', kod: 'ISB' },
      { banka: 'Vakıf Bank', kod: 'VKF' },
      { banka: 'Yapıkredi Bank', kod: 'YKB' },
      { banka: 'Ziraat Bank', kod: 'ZRB' },
    ]

    return {
      kayitYapildi,
      loading,
      banka_adi,
      kod,
      ent_kodu,
      id1,
      ozel_kod,
      banka_postkey,
      sira,
      renk,
      aktif,
      pos_test_mode,
      notlar,
      pos_turu,
      prod_client_id,
      prod_terminal_no,
      prod_name,
      prod_pass,
      prod_store_key,
      prod_api_url,
      prod_ucd_url,
      odeme_alt_yapi_firmasi,
      prod_mpi_url,
      prod_posnetid,
      prod_keyxcip,
      prod__3d_type,
      test_client_id,
      test_terminal_no,
      test_name,
      test_pass,
      test_store_key,
      test_api_url,
      test_ucd_url,
      test_mpi_url,
      test_posnetid,
      test_keyxcip,
      test__3d_type,
      firstname,
      bankHeaders,
      bankItems,
      sendFields,
      entHeaders,
      valid,
      entItems,
      nameRules,
      gelenBank,
      form,
      required,
      email,
      tumKartTipleri,
      three_d_items,
      bankaKoduDialog,
      mobile,
      entKoduDialog,
      password,
      card_brand,
      card_brand_items,
      checkbox,
      type,
      selectedOptions,
      validate,
      pos_turu_items,
      states,
      currentTab,

      icons: {
        mdiPointOfSale,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style>
/* Tüm label'ların rengini değiştirmek için geniş bir kapsama sahip bir stil */
label {
  color: rgb(73, 73, 73);
}
.v-application .primary--text {
  color: var(--v-secondary-base) !important;
  caret-color: var(--v-secondary-base) !important;
}
</style>
